import { ENV } from '@/utils/env';
import type * as Sentry from '@sentry/nextjs';

export const sentryEnabled = ENV !== 'LOCAL';

export const sentryDefaultConfig: Parameters<typeof Sentry.init>[0] = {
  dsn: 'https://6dc969b25f230f555846e5ac25826304@o4508047928393728.ingest.us.sentry.io/4508048012017664',
  environment: ENV,
  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false
};
